.works{
    background-color: rgb(255, 255, 255);    
    display: flex;
    align-content: center;
    flex-direction: row;
    user-select: none;
}
h1{
    margin-left: 3%;
    color: white;
}
p{
    margin-left: 3%;
    color: white;
    font-size: 170%;

}
.text{
    background-color: rgb(8, 166, 187);    
    height: 100%;
    width: 28%;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    transition: all 1s ease;
}

.slides{
    display: flex;
    flex-direction: row;
    height: 100%;

    .buttons{
        height: 100%;
        width: 73%;
        display: flex;
        position: absolute;
        align-items: center;
        z-index: 4;
        justify-content: space-between;
        .button{
            cursor: point;
            transition: all 0.3s ease;
            line-height: 110%;
            text-align: center;
            font-size: 200%;

            display: inline-block; 
            margin-inline: 2%;
            padding: 0px 0px;
            
            border:none;
            height: 5%;
            width: 3%;
            
            overflow-y: hidden;
            
            border-radius: 10%;
            background-color: rgb(7, 179, 202);
            color: white;
        }
        .button:hover{
            transition: all 0.1s ease;
            background-color: rgb(12, 134, 150);
            
        }
        .left:hover{
            transform: translateX(-15%);
        }
        .right{
            z-index: 5;
        }
        .right:hover{
            transform: translateX(15%);
        }
    }
    img{
        z-index: 1;
        transition: all 0.2s ease-in-out;
        width: 100%;
    }
}

@media only screen and (max-width: 1600px){
    .slides{
        .buttons{
            width: 73.5%;
        }
    }
}

@media only screen and (max-width: 800px) {
    .works{
        flex-direction: column;
    }
    .text{
        height: 60%;
    }
    h1{
        font-size: 250%;
    }
    p {
      font-size: 150%;
    }
    .text{
        width: fit-content;
        justify-content: start;
    }
    .slides{
        .buttons{
            align-content: center;
            width: 100%;
            height: 10%;
        }
    }
  }