@import "../../global.scss";

.topbar{
    transition: all 1s ease;
    background-color: white;
    color: $mainColor;
    width: 100%;
    height: 100px;
    position: fixed;
    z-index: 11;

    overflow-y: hidden;

    .wrapper{
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img{
            width: 48px;
            height: 70px;
        }

        .right{
            display: flex;
            .logo{
                font-size: 45px;
                font-weight: 400;  
                text-decoration: none;
                color: inherit;
            }
           
            span{
                margin-left: 5px;
                font-size: 17px;
                font-weight: 600;
            }
        }

        .left{
            display: flex;
            align-items: center;

            .logo{
                font-size: 45px;
                font-weight: 400;  
                text-decoration: none;
                color: inherit;
            }
            .hamburger{
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                margin-right: 30px;
                span{
                    width: 100%;
                    height: 3px;
                    background-color: $mainColor;
                    transform-origin: left;
                    transition: all 1s ease;
                }
            }
        }
    }

    &.active{
        background-color: rgb(7, 179, 202);
        transition: all 1s ease;
        color: white;

        .hamburger{
            span{
                &:first-child{
                    background-color: white;
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    background-color: white;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}