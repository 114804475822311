.app{
    height: 100vh;

    .sections{
        width: 100%;
        height: calc(100vh - 100px);
        background-color: rgb(248, 248, 248);
        position: relative;
        top: 100px;
        
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;

        > * {
            width: 100vw;
            height: calc(100vh - 100px);
            scroll-snap-align: start;
            box-sizing: border-box;
        }
    }
    .disable-select {
        -webkit-user-select: none;  
        -moz-user-select: none;    
        -ms-user-select: none;      
        user-select: none;
      }
}
