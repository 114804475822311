.testimonials{
    background-color: white;
    display: flex;
    align-content: center;
    flex-direction: row;
    user-select: none;
}
h1{
    margin-right: 3%;
    color: white;
}
p{
    margin-right: 3%;
    color: white;
    font-size: 170%;

}
.text3{
    background-color: rgb(224, 158, 14);    
    height: 100%;
    width: 28%;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
}

.slides3{
    display: flex;
    flex-direction: row;
    height: 100%;
    z-index: 2;

    .buttons3{
        height: 100%;
        width: 73.8%;
        z-index: 1;
        display: flex;
        position: absolute;
        align-items: center;
        z-index: 2;
        justify-content: space-between;
        .button3{
            cursor: pointer;

            transition: ease 0.5s;
            line-height: 110%;
            text-align: center;
            font-size: 200%;

            display: inline-block; 
            margin-inline: 2%;
            padding: 0px 0px;
            
            border:none;
            height: 5%;
            width: 3%;
            
            overflow-y: hidden;
            
            border-radius: 10%;
            background-color: rgb(224, 158, 14);
            color: white;
        }
        .button3:hover{
            transition: ease 0.1s;
            background-color: rgb(185, 133, 19);
            
        }
        .left3:hover{
            transform: translateX(-15%);
        }
        .right3{
            z-index: 3;
        }
        .right3:hover{
            transform: translateX(15%);
        }
    }
    
}
img{
    z-index: 1;
    transition: all 0.2s ease-in-out;
    width: 100%;
}
@media only screen and (max-width: 1600px){
    .slides3{
        .buttons3{
            width: 73.5%;
        }
    }
}

@media only screen and (max-width: 800px) {
    .works3{
        flex-direction: column;
    }
    .text3{
        height: 60%;
    }
    h1{
        font-size: 250%;
    }
    p {
      font-size: 150%;
    }
    .text3{
        width: fit-content;
        justify-content: start;
    }
    .slides3{
        .buttons3{
            align-content: center;
            width: 100%;
            height: 10%;
        }
    }
  }