
@import "../../global.scss";

.menu{
    transition: all 1s ease;
    width: 300px;
    height: 100vh;
    background-color: white;
    position: fixed;
    top:0;
    left: -300px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0%;
    z-index: 5;
    .itemContainer{
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-left: 10px;
        color: $secondTone;
        
        .icon{
            font-size: 27px;
        }
        .email{
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    a{
        text-decoration: none;
    }

    &.active{
        transition: all 1s ease;
        left: 0;
        opacity: 100%;
    }

    ul{
        margin: 0;
        padding-right: 50px;
        list-style: none;
        font-size: 30px;
        color: $secondTone;

        li{
            a{
                font-size: inherit;
                font-weight: 300;
                color: inherit;
                text-decoration: none;
            }
        }
    }
}