.contact{
    background-color: lightblue;
}
.slides4{
    display: flex;
    flex-direction: row;
    height: 100%;

    .buttons4{
        height: 100%;
        width: 73.4%;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        z-index: 4;
        justify-content: space-between;
        .button4{
            cursor: point;
            transition: all 0.3s ease;
            line-height: 110%;
            text-align: center;
            font-size: 200%;

            display: inline-block; 
            margin-inline: 2%;
            padding: 0px 0px;
            
            border:none;
            height: 5%;
            width: 3%;
            
            overflow-y: hidden;
            
            border-radius: 10%;
            background-color: rgb(219, 16, 128);
            color: white;
        }
        .button4:hover{
            transition: all 0.1s ease;
            background-color: rgb(173, 14, 102);
            
        }
        .left4:hover{
            transform: translateX(-15%);
        }
        .right4{
            z-index: 5;
        }
        .right4:hover{
            transform: translateX(15%);
        }
    }
    img{
        z-index: 1;
        transition: all 0.2s ease-in-out;
        display: flex;
        align-content: center;
        justify-content: center;
        width: 150%;
    }
}