.intro{
    padding: 20px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    background-image: url("../../images/intro-background.png");
    background-size: cover;
}
.main{
    max-width: 900px;
}
h4{
    position: absolute;
    display: flex;
    top: 97%;
    left: -3%;
    color: white;
    justify-content: start;
    margin-left: 5%;
}
h1{
    font-size: 50px;
    word-break: break-all;
    display: flex;
    align-items: center;
}
img{
    width: 100%;
}
.row,
.row > .column {
    padding: 8px;
}
.row{
    display: flex;
    align-content: center;
    flex-direction: row;
    width: 100%;
}
.column{
    float:left;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow-y: hidden;

}
//???
.row:after{
    content: "";
    display: table;
    clear: both;
}

.content{
    transition: all 0.5s transform;
    background-color: white;
    padding: 10px;
    box-shadow: 0 0 5px grey;
    width:90%;
    cursor: pointer;
}

.content:hover{
    transition: all 0.5s transform;
    box-shadow: 0 0 10px grey;
}
@media screen and (max-width: 900px){
    .column{
        width: 50%;
    }
}
@media screen and (max-width: 600px) {
    .column{
        width: 100%;
    }
}